






import { defineComponent } from "@vue/composition-api";

import CommentsWrapper from "@/app/components/Comment/CommentsWrapper.vue";
import ProjectPageWrapper from "@/app/components/Project/ProjectPageWrapper.vue";
import { EntityTypeEnum } from "@/shared/enums/components/Comment/CommentsWrapper";
import { useRoute } from "@/shared/hooks";
import { EntityInfo } from "@/shared/types/components/Base/commentsWrapper";

export default defineComponent({
  components: {
    CommentsWrapper,
    ProjectPageWrapper, 
  },
  name: "project-comments",
  setup() {
    const route = useRoute();
    const projectId = route.params.id;
    const entityInfo: EntityInfo = {
      id: projectId,
      entityType: EntityTypeEnum.PROJECT,
    };
    return { entityInfo };
  },
});
